@font-face {
    font-family: 'DIN Pro';
    src: url('DINPro-Black.eot');
    src: url('DINPro-Black.eot?#iefix') format('embedded-opentype'),
        url('DINPro-Black.woff2') format('woff2'),
        url('DINPro-Black.woff') format('woff'),
        url('DINPro-Black.ttf') format('truetype'),
        url('DINPro-Black.svg#DINPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

